<!--Vista para mostrar error 404-->
<template>
  <v-container fluid>
    <v-row justify="center" align="center">
      <v-col cols="12">
        <v-row justify="center" align="center" class="mt-10 text-center">
          <v-col cols="6" sm="4" lg="5">
            <v-row justify="center">
              <v-img
                src="@/assets/images/weChamber/logo-WC-black.png"
                width="100%"
                height="auto"
              ></v-img>
            </v-row>
          </v-col>
          <v-col cols="12" class="mt-10">
            <h1 class="display-2 font-weight-bold">
              ¡Proceso de validación terminado!
            </h1>
          </v-col>
          <v-col cols="12">
            <!-- <h1 class="display-12">404</h1> -->
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  created: function () {
    this.window.top.postMessage("3DS-authentication-complete");
  },
};
</script>